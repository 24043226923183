import React, { FC, memo } from 'react'
import { AdPlacementType, AdProps, AdType } from '@sport1/types/web'
import dynamic from 'next/dynamic'

import {
    PercentageType,
    PixelType,
    ResponsiveType,
    SystemSpacingsProps,
} from '@sport1/news-styleguide/Types'
import useTheme from '@sport1/react-elements/useTheme'
import darkBorderColors from '@sport1/news-styleguide/darkBorderColors'
import lightBorderColors from '@sport1/news-styleguide/lightBorderColors'
import useCurrentDeviceSize from '../../../hooks/useCurrentDeviceSize'
import DisplayAd, { DisplayAdProps } from '@/components/Ads/AdSlot/DisplayAd'
import DisplayStickyAd from '@/components/Ads/AdSlot/DisplayStickyAd'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'
import Config from '@/utils/Config'
import { ExtraAdSlotProps } from '@/utils/ads/display/createAdsPlacements.types'
import { outbrainAds } from '@/helpers/adHelper'

const OutbrainAd = dynamic(() => import('@/components/Ads/AdSlot/OutbrainAd'), { ssr: false })

export type SpacingProps = {
    marginTop?: number | number[]
    marginBottom?: number | number[]
    marginLeft?: number | number[]
    marginRight?: number | number[]
    marginX?: number | number[]
    marginY?: number | number[]
    margin?: number | number[]
}

export type BorderColors = typeof lightBorderColors | typeof darkBorderColors

type AdSlotProps = Omit<AdProps, 'type'> &
    Pick<DisplayAdProps, 'disableBottomSpace'> &
    SystemSpacingsProps & {
        enrichedWithId?: boolean
        width?: ResponsiveType<number | PixelType | PercentageType>
        scrollHeight?: number
        hasBorder?: boolean
    } & ExtraAdSlotProps

const AdSlot: FC<AdSlotProps> = ({ ad, enrichedWithId, noShow, id, hasBorder, ...rest }) => {
    const { borderColors } = useTheme() as {
        borderColors: BorderColors
    }
    const { deviceSizeDesktop } = useCurrentDeviceSize()
    const { adType, widgetIdentifiers } = ad || {}
    const { placements } = useAdPlacementContext()
    const { placement, placementId } = placements?.get(id) || {}
    const hasBorderColor = hasBorder && deviceSizeDesktop

    if (!Config.ADS_ACTIVE || noShow) {
        return null
    }

    /**
     * SKYSCRAPER is taken care of by the AdPlacementProvider, shall not
     * be rendered here.
     */
    if (ad?.placement === AdPlacementType.SKYSCRAPER) {
        return null
    }

    if (adType === AdType.DISPLAY && placement && placement === 'stickyfooter') {
        return <DisplayStickyAd placement={placement} placementId={placementId} {...rest} />
    }

    if (adType === AdType.DISPLAY && !placementId && enrichedWithId) {
        return (
            <DisplayAd placeholderType={ad.placement} placement={id} placementId={id} {...rest} />
        )
    }

    if (adType === AdType.DISPLAY) {
        return (
            <DisplayAd
                placeholderType={ad.placement}
                placement={placement}
                placementId={placementId}
                borderColor={hasBorderColor ? borderColors['border-tertiary'] : undefined}
                {...rest}
            />
        )
    }

    if (typeof window === 'undefined') {
        return null
    }

    if (adType && outbrainAds.includes(adType) && widgetIdentifiers?.web) {
        return <OutbrainAd dataWidgetId={widgetIdentifiers.web} {...rest} />
    }

    return null
}

export default memo(AdSlot)
