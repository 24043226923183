import { SizeTypes } from '@/types/size'
import { useMediaQueryContext } from '@/utils/breakpoints/MediaQuery'

export type CurrentDiviceSizeReturnValue = {
    deviceSizeDesktop: boolean
    deviceSizeTablet: boolean
    deviceSizeMobile: boolean
}

const useCurrentDeviceSize = (): CurrentDiviceSizeReturnValue => {
    const { currentDevice } = useMediaQueryContext()

    const deviceSizeDesktop = currentDevice === SizeTypes.DESKTOP
    const deviceSizeTablet = currentDevice === SizeTypes.TABLET
    const deviceSizeMobile = currentDevice === SizeTypes.MOBILE

    return { deviceSizeDesktop, deviceSizeTablet, deviceSizeMobile }
}

export default useCurrentDeviceSize
